<template>
  <div class="mobileUsb">
       <mHead :name="'五孔带USB电源面板'" :add="'/mobile/ProUsb'" :adds="'/mobile/ProUsbParam'" :color="true" />
    <div class="banner">
      <div class="title">五孔带USB电源面板</div>
      <div class="text">USB 充电插座二合一</div>
      <div class="ul">
        <div class="li">
          <img src="../../assets/mobile/product/usbIcon1.png" class="liImg" />
          <div class="liName">高效快充</div>
        </div>
        <div class="li">
          <img src="../../assets/mobile/product/usbIcon2.png" class="liImg" />
          <div class="liName">智能分流</div>
        </div>
      </div>
      <div class="ul uls">
        <div class="li">
          <img src="../../assets/mobile/product/usbIcon3.png" class="liImg" />
          <div class="liName">自适应设备</div>
        </div>
        <div class="li">
          <img src="../../assets/mobile/product/usbIcon4.png" class="liImg" />
          <div class="liName">多重防护</div>
        </div>
      </div>
    </div>
    <div class="sec1">
      <div class="secTit">三种色彩 演绎万种风情</div>
      <div class="secBox">
        <van-swipe class="my-swipe" :autoplay="3000">
          <van-swipe-item class="item">
            <img src="../../assets/mobile/product/usbSwipe1.png" class="swImg" />
            <div class="sName">黑色</div>
          </van-swipe-item>
          <van-swipe-item class="item">
            <img src="../../assets/mobile/product/usbSwipe2.png" class="swImg" />
            <div class="sName">白色</div>
          </van-swipe-item>
          <van-swipe-item class="item">
            <img src="../../assets/mobile/product/usbSwipe3.png" class="swImg" />
            <div class="sName">香槟金</div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="sec2">
      <div class="secBox">
        <div class="tit">充电便捷</div>
        <div class="text">娱乐充电两不误</div>
        <div class="text texts">可满足多个设备同时充电</div>
        <div class="mask">卧室</div>
      </div>
      <div class="secBox">
        <div class="tit">无需走动</div>
        <div class="text">沙发旁装一个，躺着坐着</div>
        <div class="text texts">随便充，来客人充电更方便</div>
        <div class="mask">客厅</div>
      </div>
      <div class="secBox">
        <div class="tit">边吃边充</div>
        <div class="text">刷手机看视频</div>
        <div class="text texts">手机怎么可以没电</div>
        <div class="mask">餐厅</div>
      </div>
    </div>
    <div class="sec3">
      <div class="secBox">
        <div class="imgBox">
          <img src="../../assets/mobile/product/usb4.png" class="img" />
        </div>
        <div class="tit">快速充电 整装待发</div>
        <div class="text">2个USB充电插口,每个插口均支持 2.1A 快充</div>
        <div class="text texts">满足多种设备同时充电</div>
      </div>
      <div class="secBox">
        <div class="imgBox">
          <img src="../../assets/mobile/product/usb5.png" class="img" />
        </div>
        <div class="tit">阻燃材料 杜绝隐患</div>
        <div class="text">加强金属片，发热更低</div>
        <div class="text texts">采用阻燃材料，保障可靠性</div>
      </div>
      <div class="secBox">
        <div class="imgBox">
          <img src="../../assets/mobile/product/usb6.png" class="img" />
        </div>
        <div class="tit">五孔供电 插头不打架</div>
        <div class="text">实用性更强，可同时为多个电器和</div>
        <div class="text texts">电子设备供电，安全实用</div>
      </div>
      <div class="secBox">
        <div class="imgBox">
          <img src="../../assets/mobile/product/usb7.png" class="img" />
        </div>
        <div class="tit">智能分配电流 保护您的设备</div>
        <div class="text">双口 2.1A 输出，同时为设备供电</div>
        <div class="text texts">自适应电流设计，智能识别设备，智能分配电流</div>
      </div>
    </div>
  </div>
</template>

<script>
import mHead from "../../components/MobileHead.vue";
export default {
  components: {
    mHead
  },
  created() {}
};
</script>
<style scoped lang="less">
.banner {
  width: 100%;
  height: 8rem;
  color: #333333;
  background-image: url(../../assets/mobile/product/usbBanner.png);
  background-size: 100% 100%;
  padding-top: 0.5rem;
  .title {
    font-size: 0.48rem;
    line-height: 0.48rem;
    font-weight: bold;
  }
  .text {
    font-size: 0.3rem;
    line-height: 0.3rem;
    font-weight: 400;
    margin-top: 0.16rem;
  }
  .ul {
    margin-top: 5.08rem;
    padding: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:last-of-type {
      margin-top: 0.29rem;
    }
    .li {
      display: flex;
      justify-content: center;
      align-items: center;
      &:nth-of-type(2) {
        .liImg {
          width: 0.46rem;
          height: 0.33rem;
          margin-right: 0.29rem;
        }
      }

      .liImg {
        display: block;
        width: 0.41rem;
        height: 0.41rem;
        margin-right: 0.32rem;
      }
      .liName {
        font-size: 0.24rem;
        line-height: 0.24rem;
        font-weight: 400;
      }
    }
  }
  .uls {
    .li {
      .liImg {
        width: 0.43rem;
        height: 0.36rem;
        margin-right: 0.32rem;
      }

      &:nth-of-type(2) {
        .liImg {
          width: 0.4rem;
          height: 0.46rem;
          margin-right: 0.32rem;
        }
      }
    }
  }
}
.sec1 {
  width: 100%;
  background-color: #fff;
  padding-top: 0.8rem;
  .secTit {
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
    color: #333333;
  }
  .secBox {
    width: 100%;
    height: 8rem;
    margin-top: 0.4rem;
    .my-swipe {
      width: 100%;
      height: 8rem;
      .item {
        position: relative;
        &:last-of-type {
          .sName {
            right: 1.55rem;
          }
        }
        .swImg {
          width: 100%;
          height: 8rem;
        }
        .sName {
          position: absolute;
          top: 5.1rem;
          right: 1.7rem;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: 400;
          color: #333333;
        }
      }
      /deep/ .van-swipe__indicators {
        top: 5.7rem;
        left: 5.76rem;

        .van-swipe__indicator {
          width: 0.32rem;
          height: 0.32rem;
          margin-right: 0.36rem;
          background-color: #1e1e1e;
          opacity: 1;
          border: 0.04rem solid #666;
          &:nth-of-type(2) {
            background-color: #f2f2f2;
          }
          &:nth-of-type(3) {
            background-color: #b8b093;
          }
        }
      }

      /deep/.van-swipe__indicator--active {
        background-color: "fff";
        border: 0.04rem solid #ff9000 !important;
      }
    }
  }
}
.sec2 {
  width: 100%;
  padding: 0 0.3rem;
  padding-top: 0.8rem;
  background-color: #fff;
  .secBox {
    width: 100%;
    height: 4rem;
    background-image: url(../../assets/mobile/product/usb1.png);
    background-size: 100% 100%;
    color: #ffffff;
    position: relative;
    margin-bottom: 0.3rem;
    &:nth-of-type(2) {
      background-image: url(../../assets/mobile/product/usb2.png);
      background-size: 100% 100%;
    }
    &:last-of-type {
      margin-bottom: 0;
      background-image: url(../../assets/mobile/product/usb3.png);
      background-size: 100% 100%;
    }
    .tit {
      padding-top: 0.79rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 800;
    }
    .text {
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      margin-top: 0.2rem;
    }
    .texts {
      margin-top: 0.16rem;
    }
    .mask {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0.6rem;
      font-size: 0.3rem;
      line-height: 0.6rem;
      font-weight: 400;
      color: #ff9000;
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
}
.sec3 {
  width: 100%;
  padding: 0.8rem 0px;
  background-color: #fff;
  .secBox {
    width: 100%;
    text-align: right;
    color: #333333;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.4rem;
    &:nth-of-type(2n) {
      text-align: left;
      align-items: flex-end;
    }
    &:last-of-type{
        margin-bottom: 0px;
    }
    .imgBox {
      width: 5.6rem;
      .img {
        width: 100%;
      }
    }
    .tit {
      width: 5.6rem;
      margin-top: 0.29rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 800;
    }
    .text {
      width: 5.6rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      margin-top: 0.19rem;
    }
    .texts {
      margin-top: 0.16rem;
    }
  }
}
</style>